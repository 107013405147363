.each-slide > div:not(.text) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.slide-bg {
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-slide .text {
  padding: 0;
  font-size: 0.8em;
  text-align: center;
}

.indicator {
  cursor: pointer;
  padding: 5px;
  text-align: center;
  border: 1px #666 solid;
  border-radius: 10px;
  margin: 0 2px;
}

.indicator.active {
  color: #fff;
  background: #666;
  width: 20px;
}

.react-slideshow-container + ul.indicators {
  padding: 0;
}


.react-slideshow-container {
  position: relative;
}

.with__gradient >
.react-slideshow-container::before,
.react-slideshow-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 33px;
  z-index: 2;
}

.with__gradient > .react-slideshow-container::before {
  background: linear-gradient(180deg, #e9dfd2ed, transparent);
  top: 0;
}

.with__gradient > .react-slideshow-container::after {
  background: linear-gradient(360deg, #e9dfd2, transparent);
  bottom: 0;
}
