$small: 300px;
$medium: 900px;

$lightOrange: #feb29c;
$themeBlue: #014d63;
$customBreakpointOne: 339px;

* {
    -webkit-tap-highlight-color: transparent;

    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

input,
input:before,
input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

@font-face {
    font-family: Raleway;
    src: url(../fonts/Raleway/Raleway-VariableFont_wght.ttf);
}

@mixin hover-scale {
    &:hover {
        cursor: pointer;
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(1.1);
    }
}

body {
    margin: 0;
    font-family: Raleway;
    font-size: 14px;
}

.no__font {
    font-family: sans-serif;
}

.bold {
    font-variation-settings: "wght" 600;
}

.blue {
    color: #014d63;
    font-variation-settings: "wght" 420;
}

.text__center {
    text-align: center;
}

.transparent__btn {
    background: none;
    border: none;
    outline: none;
}

.bg__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#trace__header {
    background: top / cover no-repeat url(../images/headerBg.png);
    padding: 20px 30px 50px;
    background-color: #d6d2a1;
    background-blend-mode: hard-light;
}

#logo__container {
    display: flex;
    justify-content: space-between;
}

.img__logo {
    object-fit: contain;
    max-width: 60px;
    filter: drop-shadow(2px 4px 6px white);
}

#trace__parent {
    max-width: 800px;
    margin: auto;
    // overflow: hidden;
}

.bold {
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    font-variation-settings: "wght" 600;
}

.action__icon__btn {
    background-size: contain;
    height: 60px;
    width: 60px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;

    &#cotton__chain__exploitation {
        background-image: url(../images/updated/cottonChainExploitation.png);
    }

    &#why__blockchain {
        background-image: url(../images/updated/whyBlockchain.png);
    }

    &#farmer__owned__initiative {
        background-image: url(../images/updated/farmerOwnedInitiative.png);
    }
}

.blue__section {
    padding: 60px 20px;
    background-color: #014d63;
    padding-top: 15px;
    background-color: #014d63;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    position: relative;
    bottom: 25px;

    *.action__btn__container {
        text-align: center;
        width: 33%;

        @include hover-scale;

        &:hover {
            background-color: #ffffff2e;
            border-radius: 10px;
        }
    }
}

.white__section {
    background: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    position: relative;
    bottom: 50px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #farmer__earnings.white__box {
        overflow: visible;
        bottom: 40px;
        margin-bottom: 0;
        cursor: default;
    }
}

.farmer__icon__container {
    background-color: #fefce3;
    padding: 10px 15px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 2px 11px 2px #0000001f;
    position: relative;
    bottom: 25px;
    text-align: center;
    z-index: 1;

    #farmer__icon {
        width: 60px;
        height: 60px;
        position: relative;
        left: 10px;
        background: url(../images/updated/farmerIcon.svg) center no-repeat;
        background-size: contain;
        margin: auto;
    }

    @include hover-scale;

    &:hover {
        background-color: #fdf7ab;
    }
}

.white__trace__container {
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    padding: 0 25px;
}

section.white__trace__container:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    // background-color: #f05023;
    background-color: $lightOrange;
    left: 70px;
}

.white__box {
    background-color: white;
    padding: 30px 15px;
    border-radius: 15px;
    /* box-shadow: 0 2px 11px 2px #0000001f; */
    box-shadow: 0 2px 18px 14px #0000000f;
    position: relative;
    overflow: hidden;
    margin-bottom: 35px;

    @include hover-scale;

    .main__details {
        display: flex;
        font-family: sans-serif;
        /* justify-content: space-between; */
        justify-content: flex-start;

        .amount {
            display: flex;
            align-items: center;
            /* width: 30%; */
            width: 3em;
            padding-right: 8px;
            font-size: 20px;
            font-weight: bold;

            font-size: 16px;
            font-family: sans-serif;
            /* word-break: break-word; */
            width: 3em;
            color: grey;
        }

        .checkpoint {
            text-align: center;
            flex: 1;
            max-width: calc(100% - 90px);

            .name {
                font-size: 17px;

                @extend .bold;

                display: flex;
                justify-content: space-between;
                // justify-content: flex-start;
                align-items: center;
                text-align: left;
            }

            .description {
                font-family: "Raleway";
                font-variation-settings: "wdth" 198, "wght" 460, "opsz" 42;
                font-size: 12px;
                text-align: left;
            }
        }
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 100%;
        max-height: 100px;
        background-size: cover;
        background-position-y: center;
        background-repeat: no-repeat;
        top: 0;
        width: 90px;
        opacity: 0.3;

        @media screen and (max-width: $customBreakpointOne) {
            opacity: 0.2 !important;
            max-height: 65px;
            width: 60px;
        }

        @media screen and (min-width: $customBreakpointOne) and (max-width: 475px) {
            max-height: 80px;
            width: 68px;
        }
    }

    // Refactor these into styled-components

    &#retail__store::after {
        background-image: url(../images/updated/retailStore.png);
    }

    &#apparel__making::after {
        background-image: url(../images/updated/apparelMaking.png);
    }

    &#artisans::after {
        background-image: url(../images/updated/artisans.png);
    }

    &#cotton__processing::after {
        background-image: url(../images/updated/cottonProcessing.png);
    }

    &#harvest::after {
        background-image: url(../images/updated/harvest.png);
    }

    &#selfie {
        padding: 0;

        .checkpoint {
            padding: 30px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 100%;

            .name {
                text-align: center;
            }
        }

        &::after {
            content: none;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.expanded__details {
    margin: 20px 3vw 0 4vw;
    padding: 10px;
    border-radius: 5px;

    .row__container {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #e1e1e1;

        @include hover-scale;

        .percent {
            padding: 10px;
            background-color: #f05023;
            color: white;
            font-family: sans-serif;
            border-radius: 5px;
            align-self: flex-start;
            min-width: 40px;
            text-align: right;
        }

        .item__name {
            font-weight: bold;
            color: #f05023;
            font-size: 16px;
        }

        .subscript {
            font-size: 12px;
            font-variation-settings: "wdth" 198, "wght" 460, "opsz" 42;
        }
    }
}

.hide__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 15px);
    padding: 10px;
    width: 50%;
    border-radius: inherit;
    background-color: #014d63;
    color: white;
    font-variation-settings: "wght" 700;
    font-family: inherit;
    border: none;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: 0.3s all ease-in-out;
        background-color: #f05023;
    }
}

.arrow__icon {
    width: 20px;
    height: 12px;
    background: url(../images/updated/icon-down.svg) center no-repeat;
    display: inline-block;
    padding-left: 10px;
    filter: invert(1);

    @media screen and (min-width: $customBreakpointOne) {
        position: relative;
        right: 8%;
    }

    &.up {
        transform: rotate(180deg);
        position: relative;
        bottom: 2px;
    }

    &.right {
        transform: rotate(270deg);
    }
}

.box__text__border {
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 20px;
    border-top: 3px solid rgb(231, 204, 167);
    border-right: none;
    border-bottom: 3px solid rgb(1, 77, 99);
    border-left: none;
}

// Google Maps Styles

.process__select {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .process__select__btn {
        outline: none;
        border: 1px solid grey;
        border-radius: 10px;
        background-color: white;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
            background-color: $lightOrange;
            color: black;
        }
    }
}

.map__container {
    box-shadow: 0 0 4px #feb29c;
    height: 300px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.map__marker {
    background-color: $themeBlue;
    color: white;
    padding: 10px;
    min-width: max-content;
    max-width: 300px;
    box-sizing: border-box;
    @include hover-scale;
    position: relative;
    // overflow: hidden;
    border-radius: 5px;

    @for $i from 0 through 4 {
        &._#{$i} {
            background-color: lighten($themeBlue, $i * 2%);
        }
    }

    &.small::before {
        height: 5px;
        width: 5px;
        bottom: -3px;
    }

    &:hover {
        opacity: 1;
        z-index: 2;
    }

    &::before {
        content: "";
        background-color: #014d63;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -8px;
        -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
        clip-path: polygon(0 0, 0% 100%, 100% 0);
    }
}

@keyframes hover__transition {
    0% {
        left: -50%;
    }
    100% {
        left: 110%;
    }
}

// Selfie module

.selfie__modal__parent {
    // min-height: 500px;
    margin: -20px;
    padding: 10px;

    .title__close__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            flex: 1;

            &.title__text {
                min-width: fit-content;
            }

            &.close__btn__container {
                text-align: right;

                #close__btn {
                    width: 30px;
                    height: 30px;
                    filter: brightness(0.5);
                    @extend .bg__img;
                    @extend .transparent__btn;
                    background-image: url(../images/updated/closeIcon.png);
                    cursor: pointer;
                    transition: 0.2s all ease-in-out;

                    &:hover {
                        filter: brightness(0.1);
                    }
                }
            }
        }
    }

    .container {
        .upload__container {
            position: relative;
            background-color: #fbf8f4;
            height: 60vh;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            text-align: center;

            border-radius: 15px;
            cursor: pointer;
            padding: 15px;

            .leaf {
                position: absolute;
                width: 20px;
                height: 50px;
                @extend .bg__img;
                background-image: url(../images/updated/ruLogoLeaf.png);
                opacity: 0.6;

                &.top {
                    top: 0;
                    left: 0;
                }

                &.bottom {
                    bottom: 0;
                    right: 0;
                }
            }

            &:hover {
                background-color: #efe8de;
            }
        }

        button.action__btn {
            @extend .bg__img;
            @extend .transparent__btn;
            cursor: pointer;
            padding: 10px;
            width: 50px;
            height: 50px;

            &.retake {
                background-image: url(../images/updated/retakePhoto.svg);
            }

            &.submit {
                background-image: url(../images/updated/submitIcon.svg);
            }
        }

        .input__disc__container {
            text-align: center;
            margin: 10px 0;

            .name__label {
                font-size: 10px;
                color: grey;
            }

            input.name__input {
                font-size: 20px;
                border: none;
                border-bottom: 2px dashed grey;
                text-align: center;
                outline: none;
            }

            .disclaimer__btn {
                background: transparent;
                font-size: 10px;
                border: none;
                outline: none;
                margin-top: 15px;
                color: #eb0b33;

                background-color: #ffe1e3;
                padding: 10px;
                border-radius: 10px;

                cursor: pointer;
                transition: 0.3s all ease-in-out;

                &:hover {
                    filter: brightness(85%);
                }
            }
        }

        .action__btns__container {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;

            .action__btn.submit {
                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}
